import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LogoSection = () => {
  const { logoBPK } = useStaticQuery(graphql`
    {
      logoBPK: file(relativePath: { eq: "LOGO-BPK-PKK-KAJ.jpg" }) {
        childImageSharp {
          fixed(height: 150, width: 150, fit: CONTAIN, background: "white") {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center align-items-center py-5">
        <h6
          className="h1 px-1"
          style={{ fontFamily: "Bangers", textAlign: "center" }}
        >
          The Day We Meet
        </h6>
        <Img fixed={logoBPK.childImageSharp.fixed} />
      </div>
    </React.Fragment>
  )
}
export default LogoSection
