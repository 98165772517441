import React, { useState } from "react"
import { Link } from "gatsby"

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false)

  return (
    <React.Fragment>
      <nav
        className="fixed-top navbar navbar-expand-lg navbar-dark"
        style={{ backgroundColor: "#111111" }}
      >
        <Link
          className="navbar-brand d-lg-none"
          to="/"
          style={{ fontFamily: "Bangers, sans-serif" }}
        >
          The Day We Meet
        </Link>
        <button
          onClick={() => setOpenMenu(!openMenu)}
          className={`navbar-toggler ${openMenu && `collapsed`}`}
          type="button"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse justify-content-center ${openMenu &&
            `show`}`}
        >
          <ul
            className="navbar-nav text-uppercase align-items-end"
            style={{ fontFamily: "Fira Sans Condensed" }}
            onClick={() => setOpenMenu(!openMenu)}
          >
            <li className="nav-item">
              <Link className="nav-link text-light" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light" href="./#venue-date">
                Venue & Date
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light" href="./#speaker">
                Speaker
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light" href="./#join-us">
                Join Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </React.Fragment>
  )
}
export default Navbar
