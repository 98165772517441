import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroWelcome from "../components/heroWelcome"
import LogoSection from "../components/logoSection"
import VenueAndDateSection from "../components/venueAndDateSection"
import KeyMessageSection from "../components/keyMessageSection"
import KeyMessageDescriptionSection from "../components/keyMessageDescriptionSection"
import SpeakerSection from "../components/speakerSection"
import CallToActionSection from "../components/callToActionSection"

const IndexPage = () => {
  return (
    <React.Fragment>
      <SEO title="Come and Join Us!" />
      <Layout>
        <HeroWelcome />
        <LogoSection />
        <VenueAndDateSection />
        <KeyMessageSection />
        <KeyMessageDescriptionSection />
        <SpeakerSection />
        <CallToActionSection />
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage
