import React from "react"
import Countdown from "../components/countdown"

const VenueAndDateSection = () => {
  return (
    <React.Fragment>
      <div
        style={{ background: "#111111", fontFamily: "Fira Sans Condensed" }}
        className="text-light py-5 text-uppercase"
        id="venue-date"
      >
        <h2 className="text-center">
          Sabtu, 31 Agustus 2019 | Pukul 15.30 WIB
        </h2>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://calendar.google.com/calendar/r/eventedit?text=TDWM:+Banyak+Gaya+(Quarter+Life+Crisis)&dates=20190831T200000Z/20190831T030000Z&details=Mulai+Pk+15.30+WIB+|+Free+of+Charge&location=Auditorium+Gedung+Yustinus+Lt.+15,+Universitas+Katolik+Atmajaya"
          className="text-decoration-none text-light text-center d-block"
        >
          &gt;&gt; Tambahkan ke Jadwal Saya &lt;&lt;
        </a>
        <div className="my-5" />

        <Countdown />
        <div className="my-5" />
        <h2 className="text-center">
          Auditorium Gedung Yustinus Lt. 15, Universitas Katolik Atmajaya
        </h2>
        <a
          href="https://goo.gl/maps/LJkWsTJmmihnSSoJ6"
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none text-light text-center d-block"
        >
          &gt;&gt; Lihat di Map &lt;&lt;
        </a>
      </div>
    </React.Fragment>
  )
}

export default VenueAndDateSection
