import React from "react"
import styles from "./keyMessageSection.module.css"

const KeyMessageSection = () => {
  return (
    <React.Fragment>
      <div className={`${styles.background} py-5`}>
        <div className="container">
          <h2
            className="text-center display-4"
            style={{ fontFamily: "Bangers" }}
          >
            Siapa nih yang keBANYAKan GAYA demi di notice sama orang lain??
          </h2>
        </div>
      </div>
    </React.Fragment>
  )
}
export default KeyMessageSection
