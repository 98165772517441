import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const SpeakerSection = () => {
  const { toni, agustinus } = useStaticQuery(graphql`
    {
      toni: file(relativePath: { eq: "speakers/toni-periyanto.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      agustinus: file(relativePath: { eq: "speakers/Agustinus.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div id="speaker" className="py-5">
        <div className="row mx-auto" style={{ maxWidth: "700px" }}>
          <div className="col-12 col-md-6 mt-5 mt-md-0 text-center">
            <h2 className="h6">Perayaan Ekaristi dipimpin oleh:</h2>
            <p className="h1" style={{ fontFamily: "Bangers" }}>
              Rm. Agustinus Handoko, Msc
            </p>
            <Img fixed={agustinus.childImageSharp.fixed} />
          </div>
          <div className="col-12 col-md-6 mt-5 mt-md-0 text-center d-flex flex-column align-items-center">
            <h2 className="h6">Pewartaan Firman oleh:</h2>
            <p className="h1" style={{ fontFamily: "Bangers" }}>
              Toni Periyanto
            </p>
            <div className="flex-grow-1" />
            <Img fixed={toni.childImageSharp.fixed} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SpeakerSection
