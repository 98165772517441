import React from "react"

const KeyMessageDescriptionSection = () => {
  return (
    <React.Fragment>
      <div className="py-5 mx-auto">
        <div style={{ maxWidth: "650px" }} className="px-1">
          <p className="text-center lead">
            Menurut teori fisika: Gaya sebuah benda dipengaruhi oleh berat. Jika
            hidup anda semakin berat, mungkin anda kebanyakan gaya..
          </p>
          <p className="text-center">
            Jika hidup ingin ada perubahan, jangan BANYAK GAYA, tapi perbesar
            USAHA..
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}
export default KeyMessageDescriptionSection
