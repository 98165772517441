import React, { useState, useEffect } from "react"
import moment from "moment"
import countdown from "countdown"

const Countdown = () => {
  const [displayCountdown, setDisplayCountdown] = useState("")
  const [displayCountdownDays, setDisplayCountdownDays] = useState("00")
  const [displayCountdownHours, setDisplayCountdownHours] = useState("00")
  const [displayCountdownMinutes, setDisplayCountdownMinutes] = useState("00")
  const [displayCountdownSeconds, setDisplayCountdownSeconds] = useState("00")

  useEffect(() => {
    setInterval(() => updateCountdown(), 1000)
  }, [displayCountdownSeconds])

  function updateCountdown() {
    setDisplayCountdownDays(moment("2019-08-31T08:30:00Z").countdown().days)
    setDisplayCountdownHours(moment("2019-08-31T08:30:00Z").countdown().hours)
    setDisplayCountdownMinutes(
      moment("2019-08-31T08:30:00Z").countdown().minutes
    )
    setDisplayCountdownSeconds(
      moment("2019-08-31T08:30:00Z").countdown().seconds
    )
  }
  return (
    <React.Fragment>
      <div className="text-center">
        <h2 className="text-center display-4">Save the date!</h2>
        <div className="d-flex justify-content-center">
          <div className="mx-4">
            <h6 className="h1">{displayCountdownDays}</h6>
            <p>Hari</p>
          </div>
          <div className="mx-4">
            <h6 className="h1">{displayCountdownHours}</h6>
            <p>Jam</p>
          </div>
          <div className="mx-4">
            <h6 className="h1">{displayCountdownMinutes}</h6>
            <p>Menit</p>
          </div>
          <div className="mx-4">
            <h6 className="h1">{displayCountdownSeconds}</h6>
            <p>Detik</p>
          </div>
        </div>
        <h6 className="h1">{displayCountdown}</h6>
      </div>
    </React.Fragment>
  )
}

export default Countdown
