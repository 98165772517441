import React from "react"
import styles from "./callToActionSection.module.css"

const CallToActionSection = () => {
  return (
    <React.Fragment>
      <div className={`${styles.background} py-5`} id="join-us">
        <div className="container text-center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://calendar.google.com/calendar/r/eventedit?text=TDWM:+Banyak+Gaya+(Quarter+Life+Crisis)&dates=20190831T200000Z/20190831T030000Z&details=Mulai+Pk+15.30+WIB+|+Free+of+Charge&location=Auditorium+Gedung+Yustinus+Lt.+15,+Universitas+Katolik+Atmajaya"
          >
            <button
              className="btn btn-lg p-0 m-0"
              style={{ fontFamily: "Bangers", fontSize: "3rem" }}
            >
              CLICK TO SAVE THE DATE & THE VENUE!
            </button>
          </a>
          <p className="h1">Don't miss it and join us!</p>
          <p className="lead">*Free of Charge</p>
        </div>
      </div>
    </React.Fragment>
  )
}
export default CallToActionSection
