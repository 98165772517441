import React from "react"

const Footer = () => {
  return (
    <React.Fragment>
      <div
        className="py-5 text-center bg-dark text-light"
        style={{ fontFamily: "Fira Sans Condensed" }}
      >
        <p className="m-0">
          ⓒ {new Date().getFullYear().toString()} The Day We Meet Team.
        </p>
        <p className="m-0">
          Built with{" "}
          <a
            href="https://sawirstudio.com"
            rel="noopener noreferrer"
            className="text-decoration-none text-light"
          >
            Sawir Studio
          </a>
        </p>
      </div>
    </React.Fragment>
  )
}
export default Footer
