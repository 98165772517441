import React from "react"
import styles from "./heroWelcome.module.css"

const HeroWelcome = () => {
  return (
    <React.Fragment>
      <div className={`${styles.hero} position-relative w-100`}>
        <div
          className="position-absolute w-100 d-flex justify-content-center align-items-center"
          style={{
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            fontFamily: "bangers",
          }}
        >
          <h1 className="text-center" style={{ color: "#111111" }}>
            Banyak Gaya!
          </h1>
        </div>
      </div>
    </React.Fragment>
  )
}
export default HeroWelcome
